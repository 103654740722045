import React from 'react'
import styled from 'styled-components'

const colors = {
    white: "#FFF",
    grey: "#CEBEBE",
}
export const LetterWithMessage = styled(({className, message}) => (
    <div className={className}>
        <div className="content" dangerouslySetInnerHTML={{__html: message}}></div>
    </div>
))`
position: fixed;
top: 12px;
left: 12px;
right: 12px;
bottom: 12px;
background: white;
visibility: hidden;
transform: scale(0);
transform-origin: 50% 25%;
opacity: 0;
border-radius: 2px;
margin: auto;
max-width: 400px;
max-height: 800px;
${({open}) => open ? `
    visibility: visible;
    transition: all 1s 6.5s ease-out;
    transform: scale(1);
    opacity: 1;
    overflow: scroll;
    touch-action: manipulation;
    ` : ''
}}
/deep/ .content {
    visibility: hidden;
    opacity: 0;
    transform: translateY(1em);
    padding: 48px 52px;
    ${({open}) => open ? `
        transition: all 0.5s 7.25s ease-out;
        transform: translateY(0);
        visibility: visible;
        opacity: 1;
    ` : ''
    }
}
/deep/ .content .heading {
    text-align: center;
    margin-bottom: 2em;
    position: relative;
    font-size: 19px;
}
/deep/ .content p {
    line-height: 1.66;
    font-size: 16px;
    margin-bottom: 1em;
    color: #3e1717;
}
`



const bottleStyles = (open) => `
@keyframes moveBottle {
    from { transform: translate(-50%, 0); }
    to { transform: translate(-50%, 90px); }
}
position: absolute;
bottom: 0;
height: 87.8%;
width: 100%;
left: 50%;
transform: translate(-50%, 0);
pointer-events: none;
${open ? `animation: moveBottle 2s 1s forwards;` : ''}
`

export const Bottle = styled(({className}) => (
    <svg className={className} width="402px" height="506px" viewBox="0 0 402 506">
        <path id="Top_of_glass_1_" style={{opacity: 0.7, fill: colors.white}} d="M103,49h193c8.3,0,15-6.7,15-15V15c0-8.3-6.7-15-15-15H103c-8.3,0-15,6.7-15,15v19
            C88,42.3,94.7,49,103,49z"/>
        <path id="Shadow_1_" style={{opacity: 0.1, fill: colors.grey}} d="M120.5,49C120.5,49,120,49,120.5,49c0,68,6.5,92,6.5,92c-2-90,152.5-92,152.5-92H120.5
            L120.5,49z"/>
        <path id="Gloss_1_" style={{opacity:0.5, fill: colors.white}} d="M327.3,170.8c0,0,59.1,75.2,58.6,135.7c0,0-43.4,43-129.5,38.2
            C256.4,344.7,357.9,256.7,327.3,170.8z"/>
    </svg>
))`${({open}) => bottleStyles(open)}`

export const BottleBack = styled(({className}) => (
    <svg className={className} width="402px" height="506px" viewBox="0 0 402 506">
        <path id="Glass_3_" style={{opacity: 0.32, fill: colors.white}} d="M287,108.7V49h-87h-87v60.9c0,8.6-4.6,16.5-12.1,20.8C40,165.7-0.8,231.7,0,307.2
            C1.2,418.2,93.1,507.6,204,506c109.6-1.6,198-91,198-201c0-75.3-41.4-140.9-102.6-175.3C291.8,125.4,287,117.4,287,108.7z"/>
        <path id="Rear_shadow_1_" style={{opacity: 0.3, fill: colors.grey}} d="M25,281c0,0-12.5,99.3,50,158c66,62,168,42,168,42S84,419,25,281z"/>
    </svg>
))`${({open}) => bottleStyles(open)}`


export const Letter = styled(({className}) => (
    <div className={className}>
        <div className="paper"></div>
        <svg className="envelope-base" height="139px" viewBox="0 0 289 139">
            <polygon id="Left_letter_1_" style={{fill:'#f3e6e6'}} points="144,69 0,0 0,139 144.5,139 	"/>
            <polygon id="Right_lettter_1_" style={{fill:'#f3e6e6'}} points="145,69 289,0 289,139 144.5,139 	"/>
            <path id="Bottom_of_letter_1_" style={{fill:'#F7F5F5'}} d="M151.1,53L289,139H0l137-86C141.2,50.3,146.8,50.3,151.1,53z"/>
        </svg>
        <svg className="envelope-flap" height="139px" viewBox="0 0 289 139">
            <path className="flap" id="Top_of_letter_1_" style={{fill:'#FFFFFF'}} d="M137.9,97.8L0,0h289L152,97.7C147.8,100.7,142.2,100.8,137.9,97.8z"/>
            <path className="heart" style={{fill:'#FF5747'}} d="M165.2,40.6c-14.4,0-21.2,11.7-21.2,11.7s-6.8-11.7-21.2-11.7S105,52.9,105,59.6s10.3,27,39,49.7
                c28.7-22.7,39-43,39-49.7S179.6,40.6,165.2,40.6z"/>
        </svg>
    </div>
))`
background: #f5dcdc;
width: 71.89%;
position: absolute;
height: 24%;
top: 53%;
left: 50%;
transform: translate(-50%, 0);
perspective: 1000px;
animation: ${props => props.open ? 'removeLetter 4s forwards ease-in-out' : 'null'}

@keyframes removeLetter {
    from {
      transform: translate(-50%, 0);
    }
    25% {
      transform: translate(-50%, 0) rotate(-90deg);
    }
    30% {
      transform: translate(-50%, 0) rotate(-90deg);
    }
    50% {
      transform: translate(-50%, -90px) rotate(-90deg);
    }
    100% {
      transform: translate(-50%, -90px) rotate(-360deg);
    }
}

@keyframes removePaper {
    from {
      height: 38px;
      transform: translateY(0);
    }
    69% {
      z-index: 0;
  
    }
    70% {
      z-index: 10;
    }
    80% {
      height: 100px;
      z-index: 10;
      transform: translateY(-50px);
    }
    100% {
      z-index: 10;
      height: 100px;
      transform: translateY(-50px);
    }
  }
.paper {
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 5px;
    height: 38px;
    background: white;
    border-radius: 2px;
    ${({open}) => {
        return open ? 'z-index: 1': '';
    }}
    animation: ${({open}) => open ? 'removePaper 3s 4.5s forwards' : ''}
}
svg {
    width: 100%;
    height: auto; 
    position: relative;
    z-index: 1;
}
@keyframes openFlap {
    from {
      z-index: 100;
      transform: rotateX(0);
    }
    to {
      z-index: -1;
      transform: rotateX(-180deg);
    }
}
.envelope-flap {
    position: absolute;
    top: 0; left: 0;
    transition: all 1.5s 4s;
    transform-origin: top center;
    backface-visibility: visible;
    animation: ${({open}) => open ? 'openFlap 0.2s 4.4s forwards' : ''};
    .flap {
        transition: all 0.2s 4.4s;
        ${({open}) => {
            return open ? 'fill: #f5dcdc !important;' : ''
        }}
    }
}
.envelope-base {
    svg {
        backface-visibility: visible;
    }
}
.heart {
    transition: all 0.25s 4.25s;
    opacity: ${({open}) => open ? 0 : 1};
}
`


export const Shadow = styled(({className}) => (
    <svg className={className} width="322px" height="36px" viewBox="0 0 322 36">
        <ellipse style={{fill: '#CEBEBE'}} cx="161" cy="18" rx="161" ry="18"/>
    </svg>
))`
@keyframes shadow {
    from {
      transform: scale(0.8);
      opacity: 0.25;
    }
    25% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.6);
      opacity: 0.45;
    }
    75% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
      opacity: 0.25;
    }
}
width: 140px;
position: absolute;
top: calc(50% + 200px);
left: 0; right: 0;
margin: auto;
opacity: 0.25;
animation: shadow 10s infinite ease-in-out;
`

export const Cork = styled((props) => (
    <svg style={props.corkstyle} onTouchStart={props.start} onTouchMove={props.move} onTouchEnd={props.end} className={props.className} width="159.1px" height="219px" viewBox="0 0 159.1 219">
        <path style={{fill:'#E8B995'}} d="M137.9,0H79.5H21.2C8.6,0-1.2,11,0.1,23.5l20.5,187c0.5,4.8,4.6,8.5,9.4,8.5h49.5H129c4.8,0,8.9-3.6,9.4-8.5
            l20.5-187C160.3,11,150.5,0,137.9,0z"/>
        <path style={{fill:'#D6A685'}} d="M56.5,177C10.3,55.2,30.5,11,38.1,0H21.2C8.6,0-1.2,11,0.1,23.5l20.5,187c0.5,4.8,4.6,8.5,9.4,8.5h49.5h22.9
            C85.4,211.8,64.9,199,56.5,177z"/>
    </svg>
))`
height: 38%;
width: 39.8%;
position: absolute;
top: 0;
left: 50%;
transform: translate(-50%, 0);
`