import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import './App.css'
import axios from 'axios'
import styled from 'styled-components'

import { BottleBack, Letter, Bottle, Shadow, Cork, LetterWithMessage } from './BottleComponents'
import TokenHandler from './TokenHander'

import audioclip from './droeloe-only-be-me.mp4'

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ready: false,
            open: false,
            message: '',
            firstTouchY: null,
            corkTransition: false
        }
        this.audio = React.createRef()
    }
    onTouchStart(e) {
        if (e.touches.length === 0) {
            return false;
        }
        this.setState({
            firstTouchY: e.touches[0].clientY,
            corkTransition: false
        })
    }
    onTouchMove(e) {
        if (e.touches.length === 0 || this.state.firstTouchY === null) {
            return false;
        }
        this.setState({
            corkY: this.state.firstTouchY - e.touches[0].clientY
        })
    }
    onTouchEnd(e) {
        if (this.state.corkY > 50 && this.state.message) {
            this.audio.current.play()
            this.setState({
                open: true,
                playstatus: 'PLAYING',
                firstTouchY: null,
            })
        }
        this.setState({
            corkY: this.state.corkY  > 50 && this.state.message.length > 0 ? window.innerHeight + 500 : 0,
            firstTouchY: null,
            corkTransition: true,
        })
    }
    open () {
        this.audio.current.play()
        this.setState({
            corkY: window.innerHeight + 500,
            corkTransition: true,
            open: true,
            playstatus: 'PLAYING',
            firstTouchY: null,
        })
    }
    click() {
        if (this.state.message) {
            this.open()
        }
    }
    corkStyle() {
        return {
            transform: this.state.corkY >= 0 ? `translate(-50%, ${this.state.corkY * -1}px)` : `translate(-50%, 0px)`,
            transition: this.state.corkTransition ? 'all 3s' : 'none'
        }
    }
    componentWillMount() {
        axios.defaults.headers.common['Authorization'] = window.localStorage.getItem('token')
        axios.get('https://62o9cl1lff.execute-api.ap-southeast-2.amazonaws.com/dev/message')
            .then(res => {
                this.setState({
                    message: res.data.message
                })
            })
            .catch(err => {
                console.error(err)
            })
    }
    render() {
        return (
            <div className={this.props.className + (this.state.open ? ' open' : '')}>
                <audio ref={this.audio} src={audioclip} preload="auto" />
                <Router>
                    <Route path="/:token" component={TokenHandler} />
                    <Route path="/" exact render={() => (
                        <div>
                            <Shadow />
                            <div className="bottle-group" onClick={this.click.bind(this)}>
                                <div className="bottle-ratio"></div>
                                <Cork 
                                    corkstyle={this.corkStyle()}
                                    start={this.onTouchStart.bind(this)}
                                    move={this.onTouchMove.bind(this)} 
                                    end={this.onTouchEnd.bind(this)} />
                                <BottleBack open={this.state.open} />
                                <Letter open={this.state.open} />
                                <Bottle open={this.state.open} />
                            </div>
                            <LetterWithMessage open={this.state.open} message={this.state.message} />
                        </div>
                    )} />
                </Router>
            </div>
        )
    }

}

export default styled(App)`
.bottle-ratio {
  padding-bottom: 143%;
  width: 100%;
}
@keyframes floating {
  from, to {
    transform: translate(-50%, -50%);
  }
  25%, 75% {
    transform: translate(-50%, -60%);
  }
  50% {
    transform: translate(-50%, -40%);
  }
}
.bottle-group {
    width: 140px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    animation: floating 10s infinite ease-in-out;
}
`
